import { leagues } from '../../../config/app'
import { Currency } from '../../../config/enums'
import type { PrizesActions } from '../interfaces/PrizesActions'
import type { PrizesState } from '../interfaces/PrizesState'
import type { PrizeState, PrizeStateByLeague } from '../interfaces/PrizeState'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const prizeInitialState: PrizeState = {
	value: 0,
	isValid: true,
}

const leaguePrizeInitialState: PrizeStateByLeague = {
	value: Object.fromEntries(leagues.map((league) => [league, 0])),
	isValid: true,
}

const initialState: PrizesState = {
	currency: Currency.USD,
	tournamentChampion: { value: 100, isValid: true },
	tournamentTop10: { value: 10, isValid: true },
	beatGuestWinner: { value: 0, isValid: true },
	luckyWinner: { value: 100, isValid: true },
	coLuckyWinner: { value: 100, isValid: true },
	leagueWinner: { value: Object.fromEntries(leagues.map((league) => [league, 0])), isValid: true },
}

export default function prizes(state = initialState, action: PrizesActions): PrizesState {
	switch (action.type) {
		case REDUX_DASHBOARD_INPUT_TYPES.PRIZES_STATE_UPDATE: {
			const { currency, tournamentChampion, tournamentTop10, beatGuestWinner, luckyWinner, coLuckyWinner, leagueWinner } = action.props
			return {
				...initialState,
				currency,
				tournamentChampion: { ...prizeInitialState, value: tournamentChampion },
				tournamentTop10: { ...prizeInitialState, value: tournamentTop10 },
				beatGuestWinner: { ...prizeInitialState, value: beatGuestWinner },
				luckyWinner: { ...prizeInitialState, value: luckyWinner },
				coLuckyWinner: { ...prizeInitialState, value: coLuckyWinner },
				leagueWinner: { ...leaguePrizeInitialState, value: leagueWinner },
			}
		}
		case REDUX_DASHBOARD_INPUT_TYPES.PRIZES_STATE_VALID: {
			const {
				isTournamentChampionValid,
				isTournamentTop10Valid,
				isBeatGuestWinnerValid,
				isLuckyWinnerValid,
				isCoLuckyWinnerValid,
				isLeagueWinnerValid,
			} = action.props
			return {
				...initialState,
				tournamentChampion: {
					value: state.tournamentChampion.value,
					isValid: isTournamentChampionValid,
				},
				tournamentTop10: {
					value: state.tournamentTop10.value,
					isValid: isTournamentTop10Valid,
				},
				beatGuestWinner: {
					value: state.beatGuestWinner.value,
					isValid: isBeatGuestWinnerValid,
				},
				luckyWinner: {
					value: state.luckyWinner.value,
					isValid: isLuckyWinnerValid,
				},
				coLuckyWinner: {
					value: state.coLuckyWinner.value,
					isValid: isCoLuckyWinnerValid,
				},
				leagueWinner: {
					value: state.leagueWinner.value,
					isValid: isLeagueWinnerValid,
				},
			}
		}
		default:
			return state
	}
}
